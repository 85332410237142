<template>
  <div class="p-3 sku-configurations-holder">
    <header class="mb-4">
      <h3>Excluded clients list</h3>
    </header>
    <client-configs></client-configs>
  </div>
</template>

<script>
import ClientConfigs from '../../../components/OrderManagement/ClientConfigs/ClientConfigsEdit.vue';

export default {
  name: 'ClientConfigs.vue',
  components: {
    'client-configs': ClientConfigs,
  },
};
</script>

<style lang="scss">
  @import '../../../styles/OrderManagement.scss';
</style>
