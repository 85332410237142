<template>

  <b-card class="mb-4 border-0">
    <template #header>
      <strong>Create configuration</strong>
    </template>

  <b-spinner v-if="loading"></b-spinner>
  <div v-else class="clients-holder">
    <b-row class="mb-4 mt-4">
      <div class="clients-col">
      <b-form-group
      id="input-group-1"
      label="Clients *"
      class="clients-label clients-group"
      required>
        <multiselect
            v-model="clients"
            tag-placeholder="Add a new client to exceptions"
            placeholder="Type a new client..."
            :options="clientOptions"
            track-by="label"
            label="label"
            class="clients-multiselect"
            :multiple="true"
            :taggable="false"></multiselect>
        </b-form-group>
          <p>Insert here the clients you want to configure this exception for.Start writing and click enter to add it.
            <br/>Clients added here <b>will not</b> have orders being created on Deposco warehouse.
          </p>
        </div>
      </b-row>

      <b-row >
        <b-col class="p-0">
          <button
            id="submit-client-configs"
            @click="showSubmitConfirm(submitClientConfigs)"
            class="btn btn-info btn-fill float-left font-weight-bold">
            Confirm
          </button>
          <button
            id="submit-client-configs-goback"
            class="btn btn-fill float-left font-weight-bold btn-manual-order--cancel"
            @click="showSubmitConfirm(reloadFileConfigs)">
            Cancel
          </button>
        </b-col>
      </b-row>
  </div>

  </b-card>

</template>

<script>

import axios from 'axios';
import Multiselect from 'vue-multiselect';

export default {
  name: 'client-configs',
  data() {
    return {
      clients: [],
      clientOptions: [],
      loading: false,
    };
  },
  async beforeMount() {
    await this.loadClients();
    await this.loadClientConfigs();
  },
  components: {
    Multiselect,
  },
  methods: {
    showSubmitConfirm(callback) {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(value => {
          if (!value) {
            return;
          }
          callback();
        });
    },
    async submitClientConfigs() {
      try {
        await axios.put('v1/client-configs', { client_configs: this.clients });
        await this.reloadFileConfigs();
        this.$noty.success('Client configuration saved');
      } catch (response) {
        this.$noty.error('Unable to save client configs');
        console.error(response);
      }
    },
    async loadClientConfigs() {
      this.loading = true;
      return axios
        .get('v1/client-configs')
        .then(response => {
          response.data.client_configs.forEach(payload => {
            this.clients.push({
              client_id: payload.client_id,
              name: payload.name,
              label: `${payload.name} (id:${payload.client_id})`,
            });
          });
        })
        .catch(response => {
          this.$noty.error('Unable to load clients configs');
          console.error(response);
        }).finally(() => { this.loading = false; });
    },
    async loadClients() {
      this.loading = true;
      await axios
        .get('v1/clients/all')
        .then(response => {
          response.data.payload.forEach(client => {
            const searchLabel = `${client.display_name} (id:${client.id})`;
            this.clientOptions.push({
              id: client.id, code: client.name, client_id: client.id, name: client.display_name, label: searchLabel,
            });
          });
        })
        .catch(response => {
          this.$noty.error('Unable to load clients');
          console.error(response);
        }).finally(() => { this.loading = false; });
    },
    async reloadFileConfigs() {
      this.clients = [];
      try {
        await this.loadClientConfigs();
      } catch (e) {
        console.error('Failed to load client configs file', e);
      }
    },
  },
};
</script>
